import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
  MatSnackBarRef
} from '@angular/material';


@Injectable()
export class NotificationService {
  TYPE = {
    success: 'success',
    warning: 'warning',
    danger: 'danger'
  }

  snackBarConfig: MatSnackBarConfig;
  snackBarRef: MatSnackBarRef<any>;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  snackBarAutoHide = '2000';

  constructor(private snackBar: MatSnackBar) { }

  notify(message, type) {
    this.snackBarConfig = new MatSnackBarConfig();
    this.snackBarConfig.horizontalPosition = this.horizontalPosition;
    this.snackBarConfig.verticalPosition = this.verticalPosition;
    this.snackBarConfig.duration = parseInt(this.snackBarAutoHide, 0);

    let cssClass = 'snackbar-warning';
    if (type == this.TYPE.success) {
      cssClass = 'snackbar-success';
    } else if (type == this.TYPE.danger) {
      cssClass = 'snackbar-danger';
    }
    this.snackBarConfig.panelClass = cssClass;
    
    this.snackBarRef = this.snackBar.open(message, '', this.snackBarConfig);
}

success(msg) {
  this.notify(msg, this.TYPE.success);
}

error(msg) {
  this.notify(msg, this.TYPE.danger);
}

updated() {
  this.notify('Frissítve', this.TYPE.success);
}

created() {
  this.notify('Létrhozva', this.TYPE.success);
}

deleted() {
  this.notify('Törölve', this.TYPE.success);
}

}