<ul class="breadcrumb"> 
    <li><a [routerLink]="['../']">Felhasználók</a></li>  
    <li> {{!!editMode ? user.fullName : 'Létrehoz'}}</li>  
</ul>

<ng-container *ngIf="user.id">
   <div class="flex flex-right">
       <p class="padding-10-0 ">
        <button mat-stroked-button color="warn"  (click)="newPassoword()"  
        matTooltip="A kiküldött email segítségével a felhasználó új jelszót állíthat be">
             Új Jelszó <mat-icon>send</mat-icon>
        </button>
       </p>
      <p class="padding-10-0 padding-l-10">
        <button mat-stroked-button color="accent"  (click)="verifyEmail()"
        matTooltip="Ha az email cím még nem lett megerősítve, az elküldütt email segítségével ez lehetséges">
            Emailcím Visszaigzolás <mat-icon>send</mat-icon>
        </button>
      </p>
      
   </div>
</ng-container>

<div class="section">
    <div class="header">
        <div class="title"><span>Felhasználó</span></div>
    </div>
    <form #f="ngForm" class="form-group">
        <p>
            <mat-form-field appearance="outline">
                <mat-label>Vezetéknev</mat-label>
                <input matInput name="lastName" [(ngModel)]="user.lastName" autocomplete="off" required>
            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="outline">
                <mat-label>Keresztnév</mat-label>
                <input matInput name="firstName" [(ngModel)]="user.firstName" autocomplete="off" required>
            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput name="email" [(ngModel)]="user.email" autocomplete="off" required>
            </mat-form-field>
        </p>
       <p>
            <mat-form-field appearance="outline">
                <mat-label>Szín</mat-label>
                <input matInput type="color" name="color" [(ngModel)]="user.color" autocomplete="off">
            </mat-form-field>
        </p>
        <p  class="padding-10-0">
            <mat-form-field appearance="outline">
                <mat-label>
                    Nyelv
                </mat-label>
                <mat-select name="language"  [(ngModel)]="user.language" required>
                    <mat-option [value]="enumLanguage.Hu">Magyar</mat-option>
                    <mat-option [value]="enumLanguage.Ge">Német</mat-option>
                </mat-select>
              </mat-form-field>
        </p>
        <p>
            <mat-checkbox name="isActive" [(ngModel)]="user.isActive">Aktív</mat-checkbox>
            <mat-icon matTooltip="Inaktív felhasználók nem tudnak belépni a rendszerbe">info</mat-icon>
        </p>
        <p>
            <mat-checkbox name="verified" [(ngModel)]="user.verified">Email Visszaigazolva</mat-checkbox>
            <mat-icon matTooltip="Kétféle képpen lehet egy Emailcímet validálni: 1. Checkbox segítségével. 2. Kiküldött Email segítségével, a felhaszáló validálhatja a saját emailcímét.">info</mat-icon>
        </p>
        <p>
            <mat-checkbox name="admin"  [(ngModel)]="user.admin">Admin</mat-checkbox>
            <mat-icon matTooltip="A legmagasabb hozzáférési szint a rendszerben.">info</mat-icon>
        </p>
        
        <div class="flex">
            <p class="full">
                <button mat-raised-button  color="primary" [disabled]="f.invalid"  (click)="save()">
                    Menés
                </button>
            </p>
            <button mat-mini-fab  class="mat-fab mat-danger" [disabled]="f.invalid"  (confirm-click)="delete()" confirm>
                <mat-icon>delete</mat-icon>
            </button>
        </div>
      
    </form>
</div>

<ng-container *ngIf="user.id">
    <app-user-roles [userId]="user.id"></app-user-roles>
</ng-container>

<ng-container *ngIf="user.id">
    <app-profile [userId]="user.id"></app-profile>
</ng-container>