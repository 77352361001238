import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { TaskInterest } from 'src/app/models/taskInterest.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { TaskInterestService } from 'src/app/services/taskInterest.service';
import { DlgSearchActionComponent } from 'src/app/shared/dialogs/search/dlg-search-action/dlg-search-action.component';
import { DlgSearchOrganisationComponent } from 'src/app/shared/dialogs/search/dlg-search-organisation/dlg-search-organisation.component';
import { DlgSearchUserComponent } from 'src/app/shared/dialogs/search/dlg-search-user/dlg-search-user.component';
import { EnumTaskInterestCondtion } from 'src/app/enums/tasks/taskInteresetCondition.enum';
import { EnumLanguage } from 'src/app/enums/languages.enum';


@Component({
  selector: 'app-task-interest-setup',
  templateUrl: './task-interest-setup.component.html',
  styleUrls: ['./task-interest-setup.component.scss']
})
export class TaskInterestSetupComponent extends BaseEntity implements OnInit {
  task: TaskInterest = null;

  enumTaskInterestCondtion = EnumTaskInterestCondtion;
  enumLanguage = EnumLanguage;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modelFactory: ModelFactory,
    private taskInterestService: TaskInterestService,
    private dialog: MatDialog,
  ) {
    super();

    this.task = this.modelFactory.getNewModel(TaskInterest)
  }

  ngOnInit() {
    this.loadTask();
  }

  loadTask() {
    let id = + this.route.snapshot.queryParamMap.get('id');

    if (id) {
      this.taskInterestService.get(id).subscribe(res => {
        this.task = res;
      });
    }
  }

  save() {
    (this.task.id) ? this.update() : this.create();
  }

  create() {
    this.taskInterestService.create(this.task).subscribe(res => {
      this.task = res;
    })
  }

  update() {
    this.taskInterestService.update(this.task).subscribe(res => {
      this.task = res;
    })
  }

  delete() {
    this.taskInterestService.delete(this.task).subscribe(() => {
       this.router.navigate(['../../interests'],  {
        relativeTo: this.route  
      });
    });
  }

  openTaskPage() {
    this.router.navigate(['../interest'],  {
      queryParams: {id: this.task.id}, 
      relativeTo: this.route  
    });
  }

  openAssigneeSearchDlg() {
    const dialogConfig = new MatDialogConfig();
    
    let name = (this.task.assignee) ? this.task._assignee.name : '';
    
    dialogConfig.data = {
      name: name
    };

    const dialogRef = this.dialog.open(DlgSearchUserComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(user => {
      if (user) {
          this.task.assignee = user.id;
          this.task._assignee = user;
      } else if (user == '') {
        this.task.assignee = null;
        this.task._assignee = null;
      }
    });
  }

  openOrgSearchDlg() {
    // open search dialog to get another company
    const dialogConfig = new MatDialogConfig();
    
    // current company name
    let name = (this.task.organization) ? this.task._organization.name : '';
    
    dialogConfig.data = {
      name: name
    };

    const dialogRef = this.dialog.open(DlgSearchOrganisationComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(organisation => {
      if (organisation) {
          this.task.organization = organisation.id;
          this.task._organization = organisation;
      } else if (organisation == '') {
        // if empty string set back we delete the organisation
        this.task.organization = null;
        this.task._organization = null;
      }
    });
  }

  openActionSearchDlg() {
    const dialogConfig = new MatDialogConfig();

    let name = (this.task.action) ? this.task._action.name : '';
    
    dialogConfig.data = {
      name: name
    };

    const dialogRef = this.dialog.open(DlgSearchActionComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(action => {
      if (action) {
        this.task.action = action.id;
        this.task._action = action;
      } else if (action == '') {
        this.task.action = null;
        this.task._action = null;
      }
    });
  }

}
