<form>
    <mat-form-field>
        <mat-icon class="pointer text-red"  (click)="delete()" matSuffix matTooltip="Töröl">delete</mat-icon>
        <input matInput name="txtSearch" (keyup)="search()" [(ngModel)]="txtSearch" placeholder="Keresés (min 3)"  [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete">
        <mat-option (click)="select(option)" *ngFor="let option of options" [value]="option">
            {{option.nameHu}}
        </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>