import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { ActionPlan } from 'src/app/models/actionPlan.model';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PlanningActionService } from 'src/app/services/planningAction.service';

@Component({
  selector: 'app-dlg-search-plan-action',
  templateUrl: './dlg-search-plan-action.component.html',
  styleUrls: ['./dlg-search-plan-action.component.scss']
})
export class DlgSearchPlanActionComponent {
  txtSearch: string = '';
  options = [];
  entity: ActionPlan = null;

  onClose = new EventEmitter();

  constructor(
    private service: PlanningActionService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DlgSearchPlanActionComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) { }

  search() {
    // serch is only happening if at leat 3 chars and has to pass xxx ms after the last typeing
    if (this.txtSearch.length < 3) {
      return;
    }
    
    this._doSearch();
  }

  _doSearch() {
    let options = {params: {
      search: this.txtSearch,
      limit: 20,
      archived: false
    }};

    this.service.list(options).subscribe(data => {
      this.options = data.results
     });
  }

  select(entity) {
    this.entity = entity;
    this.txtSearch = entity.name;
  }

  delete() {
    this.txtSearch = '';
    this.entity = null;
  }

  save() {
    this.onClose.emit(this.entity);
    this.dialogRef.close();
  }

}
