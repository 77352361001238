<table class="table">
    <thead>
        <tr>
            <ng-container *ngIf="type == 'email'; else nameHeader">
                <th>Email</th>
            </ng-container>
            <ng-template #nameHeader>
                <th>Név</th>
            </ng-template>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngIf="organisation">
            <tr>
                <td>
                    <ng-container *ngIf="type == 'name'">
                        <mat-checkbox (change)="select(organisation.name)"></mat-checkbox>
                    </ng-container>
                    {{organisation.name}}
                </td>     
            </tr>
            <ng-container *ngIf="type == 'email'">
                <ng-container *ngFor="let email of organisation.emails">
                    <tr>
                        <td>
                            <ng-container *ngIf="type == 'email'">
                                <mat-checkbox (change)="select(email.email)"></mat-checkbox>
                            </ng-container>
                            {{email.email}} <span class="text-help">{{email.name}}</span>
                        </td>
                    </tr>
                </ng-container> 
            </ng-container>        
        </ng-container>  

        <ng-container *ngFor="let p of people">
            <tr>
                <td>
                    <ng-container *ngIf="type == 'name'">
                        <mat-checkbox (change)="select(p.fullName)"></mat-checkbox>
                    </ng-container>
                    <ng-container *ngIf="language == 'hu'; else tmplLanguageEn">
                        {{p.lastName}} {{p.firstName}} 
                    </ng-container>
                    <ng-template #tmplLanguageEn>
                        {{p.firstName}} {{p.lastName}}
                    </ng-template>
                </td>     
            </tr>
            <ng-container *ngIf="type == 'email'">
                <ng-container *ngFor="let email of p.emails">
                    <tr>
                        <td>
                            <ng-container *ngIf="type == 'email'">
                                <mat-checkbox (change)="select(email.email)"></mat-checkbox>
                            </ng-container>
                            {{email.email}} <span class="text-help">{{email.name}}</span>
                        </td>
                    </tr>
                </ng-container> 
            </ng-container>        
        </ng-container>  
    </tbody>
</table>


