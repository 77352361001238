import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// pipes
import { IsoDate } from './pipes/isodate.pipe'
import { TruncatePipe } from './pipes/truncate.pipe'

import { CustomMaterialModule } from './material.module';

//notification
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';

// BottomSheet
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA, MAT_BOTTOM_SHEET_DEFAULT_OPTIONS } from '@angular/material/bottom-sheet';


import { DashComponent } from './dash/dash.component';
import { LoginComponent } from './public/login/login.component';
// accounts
import { AccountsComponent } from './accounts/accounts.component';
import { CompaniesComponent } from './accounts/companies/companies.component';

import { LoaderComponent } from './shared/loader/loader.component';
import { HttpErrorInterceptor } from './services/httpError.interceptor';


// Guards
import { AuthGuard } from './services/auth.guard';
import { ModuleGuard } from './services/module.guard';
// services
import { AuthService } from './services/auth.service';
import { LoaderService } from './services/loader.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenService } from './services/token.interceptor';
import { DlgErrorComponent } from './shared/dialogs/dlg-error/dlg-error.component';
import { ErrorMsgService } from './services/errorMsg.service';
import { ContactsComponent } from './contacts/contacts.component';
import { OrganisationsComponent } from './contacts/organisations/organisations.component';
import { OrganisationComponent } from './contacts/organisations/page-organisation/organisation/organisation.component';
import { OrganisationAddressComponent } from './contacts/organisations/page-organisation/organisation/organisation-address/organisation-address.component';
import { OrganisationEmailComponent } from './contacts/organisations/page-organisation/organisation/organisation-email/organisation-email.component';
import { OrganisationPhoneComponent } from './contacts/organisations/page-organisation/organisation/organisation-phone/organisation-phone.component';
import { NotificationService } from './services/notification';
import { RolesComponent } from './accounts/roles/roles.component';
import { RoleComponent } from './accounts/roles/role/role.component';
import { DlgConfirmComponent } from './shared/dialogs/dlg-confirm/dlg-confirm.component';
import { ConfirmDirective } from './directives/confirm.directive';
import { CanEditDirective } from './directives/canEdit.directive';
import { UsersComponent } from './accounts/users/users.component';
import { UserComponent } from './accounts/users/user/user.component';
import { StaticTrueFalseIconComponent } from './shared/boolean/static-true-false-icon/static-true-false-icon.component';
import { ProfileComponent } from './accounts/users/user/profile/profile.component';
import { ProfileService } from './services/profile.service';
import { UserRolesComponent } from './accounts/users/user/user-roles/user-roles.component';
import { RoleAccessComponent } from './accounts/roles/role/role-access/role-access.component';
import { QuickLogInfoComponent } from './shared/bottomSheets/quick-log-info/quick-log-info.component';
import { QuickLogInfoDetailsComponent } from './shared/bottomSheets/quick-log-info/quick-log-info-details/quick-log-info-details.component';
import { PersonsComponent } from './contacts/persons/persons.component';
import { PersonComponent } from './contacts/persons/person/person.component';
import { BottomOrganisationComponent } from './shared/bottomSheets/bottom-organisation/bottom-organisation.component';
import { BottomOrganisatonDetailsComponent } from './shared/bottomSheets/bottom-organisation/bottom-organisaton-details/bottom-organisaton-details.component';
import { BottomPeopleComponent } from './shared/bottomSheets/bottom-people/bottom-people.component';
import { BottomPeopleDetailComponent } from './shared/bottomSheets/bottom-people/bottom-people-detail/bottom-people-detail.component';
import { PersonPhoneComponent } from './contacts/persons/person/person-phone/person-phone.component';
import { PersonEmailComponent } from './contacts/persons/person/person-email/person-email.component';
import { DlgSearchOrganisationComponent } from './shared/dialogs/search/dlg-search-organisation/dlg-search-organisation.component';
import { ProjectsComponent } from './projects/projects.component';
import { OrganisationItemComponent } from './contacts/organisations/page-organisation/organisation/organisation-item/organisation-item.component';
import { ItemsComponent } from './items/items.component';
import { ItemComponent } from './items/page-item/item/item.component';
import { ProductComponent } from './products/page-product/product/product.component';
import { ProductsComponent } from './products/products.component';
import { ManufacturersComponent } from './manufacturers/manufacturers.component';
import { CategoriesComponent } from './categories/categories.component';
import { UrlParamService } from './services/urlParam.service';
import { ManufacturerComponent } from './manufacturers/page-manufacturer/manufacturer/manufacturer.component';
import { CategoryComponent } from './categories/page-category/category/category.component';
import { NullValueService } from './services/nullValue.service';
import { DlgSearchCategoryComponent } from './shared/dialogs/search/dlg-search-category/dlg-search-category.component';
import { DlgSearchManufacturerComponent } from './shared/dialogs/search/dlg-search-manufacturer/dlg-search-manufacturer.component';
import { PageManufacturerComponent } from './manufacturers/page-manufacturer/page-manufacturer.component';
import { DlgEditManufacturerComponent } from './shared/dialogs/edit/dlg-edit-manufacturer/dlg-edit-manufacturer.component';
import { ManufacturerService } from './services/manufacturer.service';
import { PageCategoryComponent } from './categories/page-category/page-category.component';
import { DlgEditCategoryComponent } from './shared/dialogs/edit/dlg-edit-category/dlg-edit-category.component';
import { PageItemComponent } from './items/page-item/page-item.component';
import { DlgSearchProductComponent } from './shared/dialogs/search/dlg-search-product/dlg-search-product.component';
import { DlgSearchItemComponent } from './shared/dialogs/search/dlg-search-item/dlg-search-item.component';
import { DlgEditProductComponent } from './shared/dialogs/edit/dlg-edit-product/dlg-edit-product.component';
import { PageProductComponent } from './products/page-product/page-product.component';
import { PageOrganisationComponent } from './contacts/organisations/page-organisation/page-organisation.component';
import { DlgEditOrganisationComponent } from './shared/dialogs/edit/dlg-edit-organisation/dlg-edit-organisation.component';
import { PagePersonComponent } from './contacts/persons/page-person/page-person.component';
import { DlgEditPersonComponent } from './shared/dialogs/edit/dlg-edit-person/dlg-edit-person.component';
import { DlgEditOrganisationItemComponent } from './shared/dialogs/edit/dlg-edit-organisation-item/dlg-edit-organisation-item.component';
import { DlgEditItemComponent } from './shared/dialogs/edit/dlg-edit-item/dlg-edit-item.component';
import { IconItemTypeComponent } from './shared/snippets/icon-item-type/icon-item-type.component';
import { RequestForgottenPassowrdComponent } from './public/request-forgotten-passowrd/request-forgotten-passowrd.component';
import { DlgEditUserProfileComponent } from './shared/dialogs/edit/dlg-edit-user-profile/dlg-edit-user-profile.component';
import { SecurityService } from './services/security.service';
import { initApp } from './services/initApp.service';
import { ModelFactory } from './services/modelFactory.service';
import { SidemenuComponent } from './common/sidemenu/sidemenu.component';
import { ProjectPlansComponent } from './project-plans/project-plans.component';
import { ProjectPlanComponent } from './project-plans/project-plan/project-plan.component';
import { ActionsPlansComponent } from './project-plans/actions-plans/actions-plans.component';
import { ActionPlanComponent } from './project-plans/actions-plans/page-action-plans/action-plan/action-plan.component';
import { ProjectActionsPlanComponent } from './project-plans/project-plan/project-actions-plan/project-actions-plan.component';
import { DlgEditPlanActionComponent } from './shared/dialogs/edit/dlg-edit-plan-action/dlg-edit-plan-action.component';
import { PageActionPlansComponent } from './project-plans/actions-plans/page-action-plans/page-action-plans.component';
import { DlgSearchPlanActionComponent } from './shared/dialogs/search/dlg-search-plan-action/dlg-search-plan-action.component';
import { ProjectSetupComponent } from './projects/project-setup/project-setup.component';
import { ProjectComponent } from './projects/project/project.component';
import { ProjectImportanceComponent } from './shared/snippets/project-importance/project-importance.component';
import { ProjectStatusComponent } from './shared/snippets/project-status/project-status.component';
import { DlgSearchProjectTemplateComponent } from './shared/dialogs/search/dlg-search-project-template/dlg-search-project-template.component';
import { ActionsSetupComponent } from './projects/project-setup/actions-setup/actions-setup.component';
import { ProjectActionsBarComponent } from './shared/snippets/project-actions-bar/project-actions-bar.component';
import { ActionComponent } from './projects/project/action/action.component';
import { EventsComponent } from './projects/project/action/events/events.component';
import { DlgCreateCommentComponent } from './shared/dialogs/dlg-create-comment/dlg-create-comment.component';
import { AvatarComponent } from './shared/snippets/avatar/avatar.component';
import { DlgSearchUserComponent } from './shared/dialogs/search/dlg-search-user/dlg-search-user.component';
import { ProjectTypesComponent } from './project-plans/project-types/project-types.component';
import { ProjectTypeComponent } from './project-plans/project-types/project-type/project-type.component';
import { TaskInterestsComponent } from './tasks/task-interests/task-interests.component';
import { TaskInterestComponent } from './tasks/task-interests/task-interest/task-interest.component';
import { TaskInterestSetupComponent } from './tasks/task-interests/task-interest-setup/task-interest-setup.component';
import { DlgSearchActionComponent } from './shared/dialogs/search/dlg-search-action/dlg-search-action.component';
import { TaskInterestPorductsComponent } from './tasks/task-interests/task-interest/task-interest-porducts/task-interest-porducts.component';
import { TaskInterestPdfsComponent } from './tasks/task-interests/task-interest/task-interest-pdfs/task-interest-pdfs.component';
import { PublicComponent } from './public/public.component';
import { FileUploadComponent } from './shared/snippets/file-upload/file-upload.component';
import { DlgSearchOrgContactComponent } from './shared/dialogs/search/dlg-search-org-contact/dlg-search-org-contact.component';
import { PdfSnippetComponent } from './shared/snippets/pdf-snippet/pdf-snippet.component';
import { DlgEditPdfSnippetComponent } from './shared/dialogs/edit/dlg-edit-pdf-snippet/dlg-edit-pdf-snippet.component';
import { SetNewPasswordComponent } from './public/set-new-password/set-new-password.component';



@NgModule({
  declarations: [
    AppComponent,
    DashComponent,
    LoginComponent,
    CompaniesComponent,
    IsoDate,
    TruncatePipe,
    LoaderComponent,
    AccountsComponent,
    DlgErrorComponent,
    ContactsComponent,
    OrganisationsComponent,
    OrganisationComponent,
    OrganisationAddressComponent,
    OrganisationEmailComponent,
    OrganisationPhoneComponent,
    RolesComponent,
    RoleComponent,
    DlgConfirmComponent,
    ConfirmDirective,
    CanEditDirective,
    UsersComponent,
    UserComponent,
    StaticTrueFalseIconComponent,
    ProfileComponent,
    UserRolesComponent,
    RoleAccessComponent,
    QuickLogInfoComponent,
    QuickLogInfoDetailsComponent,
    PersonsComponent,
    PersonComponent,
    BottomOrganisationComponent,
    BottomOrganisatonDetailsComponent,
    BottomPeopleComponent,
    BottomPeopleDetailComponent,
    PersonPhoneComponent,
    PersonEmailComponent,
    DlgSearchOrganisationComponent,
    ProjectsComponent,
    OrganisationItemComponent,
    ItemsComponent,
    ItemComponent,
    ProductComponent,
    ProductsComponent,
    ManufacturersComponent,
    CategoriesComponent,
    ManufacturerComponent,
    CategoryComponent,
    DlgSearchCategoryComponent,
    DlgSearchManufacturerComponent,
    PageManufacturerComponent,
    DlgEditManufacturerComponent,
    PageCategoryComponent,
    DlgEditCategoryComponent,
    PageItemComponent,
    DlgSearchProductComponent,
    DlgSearchItemComponent,
    DlgEditProductComponent,
    PageProductComponent,
    PageOrganisationComponent,
    DlgEditOrganisationComponent,
    PagePersonComponent,
    DlgEditPersonComponent,
    DlgEditOrganisationItemComponent,
    DlgEditItemComponent,
    IconItemTypeComponent,
    RequestForgottenPassowrdComponent,
    DlgEditUserProfileComponent,
    SidemenuComponent,
    ProjectPlansComponent,
    ProjectPlanComponent,
    ActionsPlansComponent,
    ActionPlanComponent,
    ProjectActionsPlanComponent,
    DlgEditPlanActionComponent,
    PageActionPlansComponent,
    DlgSearchPlanActionComponent,
    ProjectSetupComponent,
    ProjectComponent,
    ProjectImportanceComponent,
    ProjectStatusComponent,
    DlgSearchProjectTemplateComponent,
    ActionsSetupComponent,
    ProjectActionsBarComponent,
    ActionComponent,
    EventsComponent,
    DlgCreateCommentComponent,
    AvatarComponent,
    DlgSearchUserComponent,
    ProjectTypesComponent,
    ProjectTypeComponent,
    TaskInterestsComponent,
    TaskInterestComponent,
    TaskInterestSetupComponent,
    DlgSearchActionComponent,
    TaskInterestPorductsComponent,
    TaskInterestPdfsComponent,
    PublicComponent,
    FileUploadComponent,
    DlgSearchOrgContactComponent,
    PdfSnippetComponent,
    DlgEditPdfSnippetComponent,
    SetNewPasswordComponent
  ],
  // dynamically loaded component and in order for a ComponentFactory 
  // like: fill dlg with component
  entryComponents: [
    DlgErrorComponent,
    DlgConfirmComponent,
    DlgSearchOrganisationComponent,
    DlgSearchCategoryComponent,
    DlgSearchManufacturerComponent,
    DlgSearchProductComponent,
    DlgSearchItemComponent,
    QuickLogInfoDetailsComponent,
    BottomOrganisatonDetailsComponent,
    BottomPeopleDetailComponent,
    DlgEditManufacturerComponent,
    DlgEditCategoryComponent,
    DlgEditProductComponent,
    DlgEditOrganisationComponent,
    DlgEditPersonComponent,
    DlgEditOrganisationItemComponent,
    DlgEditItemComponent,
    DlgEditUserProfileComponent,
    DlgEditPlanActionComponent,
    DlgSearchPlanActionComponent,
    DlgSearchProjectTemplateComponent,
    DlgCreateCommentComponent,
    DlgSearchUserComponent,
    DlgSearchActionComponent,
    DlgSearchOrgContactComponent,
    DlgEditPdfSnippetComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CustomMaterialModule
  ],
  providers: [
    AuthService,
    AuthGuard,
    LoaderService,
    ErrorMsgService,
    TokenService,
    NotificationService,
    NullValueService,
    SecurityService,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [AuthService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
      deps: [TokenService, ErrorMsgService, LoaderService, NullValueService, AuthService]
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {
        duration: 2500,
        verticalPosition: 'bottom',
        horizontalPosition: 'right',
        panelClass: 'snackbar-success'
      }
    },
    { provide: MatBottomSheetRef, useValue: {} },
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} },
    { provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, useValue: { panelClass: 'bottom-sheet', hasBackdrop: true } }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

