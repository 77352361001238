import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationService } from './notification';
import { User } from '../models/user';
import { Utils } from '../shared/utils';

/**
 * This is the real USERS service!
 */

@Injectable({
    providedIn: 'root'
  })
export class UsersService {
    baseUrl = environment.baseUrl;

    URL = '/accounts/users/';
    URL_NEW_PASSWORD_REQUEST = '/accounts/password/new/request/';
    URL_SET_NEW_PASSWORD = '/accounts/password/new/';
    URL_VERIFY_EMAIL_REQUEST = '/accounts/email/verify/request/';

    dataSource$ = new BehaviorSubject<any[]>([])
    data = this.dataSource$.asObservable();

    constructor(
        private http: HttpClient,
        private notificationService: NotificationService
        ) {}

    list(options=null) {
        let url = null;
        let params = null;
        
        // set options
        let base = {url: null, params: null}
        if (options) {
            options = {...base, ...options};
        } else {
            options = base;
        }

        if (options.url) {
            // if url sent (paginated) we don't care about params just grab the data by the url
           url = options.url; 
        } else {
            url = this.baseUrl + this.URL;
            if (options.params) {
                params = Utils.getParams(options.params);
            }
        }

        return this.http.get<any>(url, {params}).pipe(
            map(res => {
                res.results = res.results.map(u => new User(u));
                return res;
            })
        )
    }

    getActiveUsers() {
        // get the list of active + verified users - slim data
        let url = this.baseUrl + this.URL + 'active_users/' ;
        return this.http.get<any>(url).pipe(
            map(res => {
                res = res.users.map(u => new User(u));
                return res;
            })
        )
    }

    get(id) {
        let url = this.baseUrl + this.URL + id + '/';
        return this.http.get<User>(url).pipe(
            map(res => new User(res))
        )
    }

    create(payload) {
        let url = this.baseUrl + this.URL;
        return this.http.post(url, payload).pipe(
            map(result => new User(result)),
            tap(res => { this.notificationService.created(); })
        )
    }

    update(payload) {
        let url = this.baseUrl + this.URL + payload.id + '/';
        return this.http.put(url, payload).pipe(
            map(res => new User(res)),
            tap(res => { this.notificationService.updated(); })
        )
    }

    delete(payload) {
        let url = this.baseUrl + this.URL + payload.id + '/';
        return this.http.delete(url, payload).pipe(
            tap(res => { this.notificationService.deleted(); })
        )
    }

    newPasswordRequest(user) {
        // send for the user an New Password Request verification
        let url = this.baseUrl + this.URL_NEW_PASSWORD_REQUEST;
        let payload = {email: user.email}
        return this.http.post(url, payload).pipe(
            tap(res => { this.notificationService.success('Email Elküldve'); })
        )
    }

    setNewPassword(token, password) {
        // send new password for the user
        // with the valid token update the password
        let url = this.baseUrl + this.URL_SET_NEW_PASSWORD + token + '/';
        let payload = {password: password}
        return this.http.post(url, payload).pipe(
            tap(res => { this.notificationService.success('Jelszó Frissítve'); })
        )
    }

    verifyEmailRequest(user) {
        // send the user email to verify the email address
        let url = this.baseUrl + this.URL_VERIFY_EMAIL_REQUEST;
        let payload = {email: user.email}
        return this.http.post(url, payload).pipe(
            tap(res => { this.notificationService.success('Email Elküldve'); })
        )
    }
}