import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ManufacturerService } from 'src/app/services/manufacturer.service';

@Component({
  selector: 'app-dlg-search-manufacturer',
  templateUrl: './dlg-search-manufacturer.component.html',
  styleUrls: ['./dlg-search-manufacturer.component.scss']
})
export class DlgSearchManufacturerComponent {
  txtSearch: string = '';
  options = [];
  timeout = null;

  constructor(
    private service: ManufacturerService,
    public dialogRef: MatDialogRef<DlgSearchManufacturerComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.txtSearch = data.name;
  }

  search() {
    // serch is only happening if at leat 3 chars and has to pass xxx ms after the last typeing
    if (this.txtSearch.length < 3) {
      return;
    }

    if (this.timeout) {
      return;
    }

    this.timeout = setTimeout(() => {
        this.timeout = null;
        this._doSearch();
    }, 700);
  }

  _doSearch() {
    let options = {params: {
      search: this.txtSearch,
      limit: 20
    }};

    this.service.list(options).subscribe(data => {
      this.options = data.results
     });
  }

  select(entity) {
      this.txtSearch = entity.nameHu;

      setTimeout(() => {
        this.dialogRef.close(entity);
      }, 300);
     
  }

  delete() {
    this.txtSearch = '';

    setTimeout(() => {
      this.dialogRef.close('');
    }, 500);
  }

}

