export enum EnumModule {
    ProjectPlanning = 'projectplanning',
    Projects = 'projects',
    Maintenance = 'maintenance',

    // ADMIN ONLY

    //Accounts
    Accounts = 'accounts',

    //BASE Modules

    //Organisation (org and contacts)
    Contacts = 'contacts',
    //Items (Item, Man. Cat. Prod.)
    Items = 'items'
  }