import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/services/notification';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-set-new-password',
  templateUrl: './set-new-password.component.html',
  styleUrls: ['./set-new-password.component.scss']
})
export class SetNewPasswordComponent implements OnInit {
  password: string;
  password2: string;
  token: string;
  // after reset the password only show login button
  showOnlyLogin = false;

  constructor(
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private userService: UsersService
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params['token'];
  });
  }

  ngOnInit() {

  }

  validatePassword() {
    if (!this.validateSamePassword()) {
      this.notificationService.error('A két jelszó nem egyforma!');
      return false;
    };

    if (!this.validateMinLength()) {
      this.notificationService.error('Minimum hossz 8!');
      return false;
    }

    if (!this.validateContainsLowerCase()) {
      this.notificationService.error('Nem tartalmaz kisbetűt');
      return false;
    }

    if (!this.validateContainsUpperCase()) {
      this.notificationService.error('Nem tartalmaz nagybetűt');
      return false;
    }

    if (!this.validateContainsNumber()) {
      this.notificationService.error('Nem tartalmaz számot');
      return false;
    }

    if (!this.validateContainsSpecialChars()) {
      this.notificationService.error('Nem tartalmaz különleges karaktert');
      return false;
    }

    return true;
  }

  validateSamePassword() {
    return this.password === this.password2;
  };

  validateContainsLowerCase() {
      return this.password.toUpperCase() != this.password;
  }

  validateContainsUpperCase() {
    return this.password.toLowerCase() != this.password;
  }

  validateMinLength() {
    return this.password.length >= 8;
  }

  validateContainsNumber() {
    return /\d/.test(this.password);
  }

  validateContainsSpecialChars() {
    let specialChars = /[!#$%&+\-<=>?@\[\])(^_{}\|]/;
    
    return specialChars.test(this.password);
  }

  updatePassword() {
      if (!this.validatePassword()) {
        return
      }

      this.userService.setNewPassword(this.token, this.password).subscribe(() => {
        this.showOnlyLogin = true;
      });
  }

}
