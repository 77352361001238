import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { OrganisationItem } from 'src/app/models/organisation';
import { OrganisationService } from 'src/app/services/organisation.service';
import { DlgSearchItemComponent } from '../../search/dlg-search-item/dlg-search-item.component';
import { DlgSearchOrganisationComponent } from '../../search/dlg-search-organisation/dlg-search-organisation.component';
import { cloneDeep } from 'lodash';
import { NotificationService } from 'src/app/services/notification';
import { DlgEditItemComponent } from '../dlg-edit-item/dlg-edit-item.component';
import { Item } from 'src/app/models/item.model';


@Component({
  selector: 'app-dlg-edit-organisation-item',
  templateUrl: './dlg-edit-organisation-item.component.html',
  styleUrls: ['./dlg-edit-organisation-item.component.scss']
})
export class DlgEditOrganisationItemComponent implements OnInit {
  @Input() orgItem: OrganisationItem;
  @Input() isDlg: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<DlgEditOrganisationItemComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private organisationService: OrganisationService,
    private dialog: MatDialog,
    private notificationService: NotificationService
  ) {
    this.orgItem = cloneDeep(data.orgItem);
  }

  ngOnInit() {
  }

  save() {
    if(!this.valid()) {
      return;
    }
  
  if (this.orgItem.id === null) {
    this.create();
  } else {
      this.update();
    }
  }

  valid() {
    let messages = [];

    if (this.orgItem.organization === null) {
      messages.push('Cég Hiányzik');
    }

    if (this.orgItem.item === null) {
      messages.push('Temék Hiányzik');
    }
    
    if (messages.length > 0) {
      messages.forEach(msg => {
        this.notificationService.error(msg);
      })

      return false;
    }

    return true;
  }

  create() {
    this.organisationService.createItem(this.orgItem).subscribe(res => {
      this.orgItem = res;
      this.dialogRef.close(this.orgItem)
    })
  }

  update() {
    this.organisationService.updateItem(this.orgItem).subscribe(res => {
      this.orgItem = res;
      this.dialogRef.close(this.orgItem)
    });
  }

  delete() {
    this.organisationService.deleteItem(this.orgItem).subscribe(() =>
      this.dialogRef.close(null)
    )}

    openOrgSearchDlg() {
      // open search dialog to get another company
      const dialogConfig = new MatDialogConfig();
      
      // current company name
      let name = (this.orgItem.organization) ? this.orgItem._organization.name : '';
      
      dialogConfig.data = {
        name: name
      };
  
      const dialogRef = this.dialog.open(DlgSearchOrganisationComponent, dialogConfig);
  
      dialogRef.afterClosed().subscribe(organization => {
        if (organization) {
            // update the origanistion for the people
            this.orgItem.organization = organization.id;
            // update the organisation details 
            this.orgItem._organization = organization;
        } else if (organization == '') {
          // if empty string set back we delete the organisation
          this.orgItem.organization = null;
          this.orgItem._organization = null;
        }
      });
    }
  
    openItemSearchDlg() {
      let dialogConfig = new MatDialogConfig(); 
      
      let item = (this.orgItem.item) ? this.orgItem._item : null;
      
      dialogConfig.data = {
        item: item
      };
  
      const dialogRef2 = this.dialog.open(DlgSearchItemComponent, dialogConfig);

      dialogRef2.componentInstance.onSave.subscribe(item => {
        if (item) {
          this.orgItem.item = item.id;
          this.orgItem._item = item;
        } else {
          this.orgItem.item = null;
          this.orgItem._item = null;
        } 
      })

    }
}
