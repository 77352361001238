import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Role } from '../models/role.model';
import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationService } from './notification';
import { Utils } from '../shared/utils';
import { RoleAccess } from '../models/roleAccess.model';
import { ListResponse } from '../interfaces/httpResponse.interface';
import { EnumModule } from '../enums/modules.enum';

@Injectable({
    providedIn: 'root'
  })
export class RoleAccessService {
    baseUrl = environment.baseUrl;

    URL = '/accounts/roles-access/';

    // list of configurable Modules (ADMIN and BASE MOSULES are Excluded)
    MODULES = [EnumModule.ProjectPlanning, EnumModule.Projects, EnumModule.Maintenance]

    // list of the roleAccess
    dataSource$ = new BehaviorSubject<any[]>([])
    data = this.dataSource$.asObservable();

    constructor(
        private http: HttpClient,
        private notificationService: NotificationService
        ) { }

    getModules() {
        return this.MODULES;
    }

    list(params=null) {
        if (params) {
            params = Utils.getParams(params);
        }

        let url = this.baseUrl + this.URL;
        return this.http.get<ListResponse>(url, {params}).pipe(
            map(res => res.results.map(data => new RoleAccess(data)))
        )
    }

    get(id) {
        let url = this.baseUrl + this.URL + id + '/';
        return this.http.get<RoleAccess>(url).pipe(
            map(res => new RoleAccess(res))
        )
    }

    create(payload) {
        let url = this.baseUrl + this.URL;
        return this.http.post(url, payload).pipe(
            map(result => new RoleAccess(result)),
            tap(res => { this.notificationService.created(); })
        )
    }


    update(payload) {
        let url = this.baseUrl + this.URL + payload.id + '/';
        return this.http.put(url, payload).pipe(
            map(res => new RoleAccess(res)),
            tap(res => { this.notificationService.updated(); })
        )
    }

    delete(payload) {
        let url = this.baseUrl + this.URL + payload.id + '/';
        return this.http.delete(url, payload).pipe(
            tap(res => { this.notificationService.deleted(); })
        )
    }
}