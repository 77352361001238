import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Product } from 'src/app/models/product.model';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-dlg-search-product',
  templateUrl: './dlg-search-product.component.html',
  styleUrls: ['./dlg-search-product.component.scss']
})
export class DlgSearchProductComponent {
  txtSearch: string = '';
  options = [];
  product: Product = new Product(null);


  constructor(
    private service: ProductService,
    public dialogRef: MatDialogRef<DlgSearchProductComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    if (data.product) {
      this.product = data.product;
      this.txtSearch = this.product.nameHu
    }
  }

  search() {
    // serch is only happening if at leat 3 chars and has to pass xxx ms after the last typeing
    if (this.txtSearch.length < 3) {
      return;
    }

    this._doSearch();
  }

  _doSearch() {
    let options = {params: {
      search: this.txtSearch,
      limit: 20
    }};

    this.service.list(options).subscribe(data => {
      this.options = data.results
     });
  }

  select(entity) {
    this.product = entity;
    this.txtSearch = entity.nameHu;

    setTimeout(() => {
      this.dialogRef.close(entity);
    }, 300);
     
  }

  delete() {
    this.txtSearch = '';
    this.product = new Product(null);

    setTimeout(() => {
      this.dialogRef.close('');
    }, 300);
  }

}

