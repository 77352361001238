import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Utils } from '../shared/utils';
import { RoleAccess } from '../models/roleAccess.model';
import { EnumModule } from '../enums/modules.enum';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./notification";
export class RoleAccessService {
    constructor(http, notificationService) {
        this.http = http;
        this.notificationService = notificationService;
        this.baseUrl = environment.baseUrl;
        this.URL = '/accounts/roles-access/';
        // list of configurable Modules (ADMIN and BASE MOSULES are Excluded)
        this.MODULES = [EnumModule.ProjectPlanning, EnumModule.Projects, EnumModule.Maintenance];
        // list of the roleAccess
        this.dataSource$ = new BehaviorSubject([]);
        this.data = this.dataSource$.asObservable();
    }
    getModules() {
        return this.MODULES;
    }
    list(params = null) {
        if (params) {
            params = Utils.getParams(params);
        }
        let url = this.baseUrl + this.URL;
        return this.http.get(url, { params }).pipe(map(res => res.results.map(data => new RoleAccess(data))));
    }
    get(id) {
        let url = this.baseUrl + this.URL + id + '/';
        return this.http.get(url).pipe(map(res => new RoleAccess(res)));
    }
    create(payload) {
        let url = this.baseUrl + this.URL;
        return this.http.post(url, payload).pipe(map(result => new RoleAccess(result)), tap(res => { this.notificationService.created(); }));
    }
    update(payload) {
        let url = this.baseUrl + this.URL + payload.id + '/';
        return this.http.put(url, payload).pipe(map(res => new RoleAccess(res)), tap(res => { this.notificationService.updated(); }));
    }
    delete(payload) {
        let url = this.baseUrl + this.URL + payload.id + '/';
        return this.http.delete(url, payload).pipe(tap(res => { this.notificationService.deleted(); }));
    }
}
RoleAccessService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RoleAccessService_Factory() { return new RoleAccessService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.NotificationService)); }, token: RoleAccessService, providedIn: "root" });
