<div class="section">
    <div class="header">
        <div class="title">
            <span>Module Hozzáférés ({{roleAccesses.length}})</span>
        </div>
    </div>

    <div class="alert alert-info">
        <ul>
            <li>
                Felhasználó rendelkezik ADMIN hozzáféréssel, bármelyik Modulhoz van teljes hozzáférése.
            </li>
            <li>
                Telefonkönyv és a Termekek: Alap Modulok, ami azt jelenti hogy minden Felhasználónak van  teljes hozzáférése.
            </li>
            <li>
                Fentieken kívül Ha egy Modul nincs az alábbi listában, akkor ahhoz nincs hozzáférése a Felhasználónak.
            </li>
            <li>
                A alábbi táblázat tartamlazza a Modulokat amelyek hozzáférése testresabható.
            </li>
        </ul>
    </div>

     <!-- add new modules-->
     <div *ngIf="availableModuels" class="flex flex-right">
        <mat-form-field appearance="standard">
            <mat-label>Új Module Hozzáadása:</mat-label>
            <mat-select [(value)]="defaultSelect" (selectionChange)="add($event)">
                <mat-option [value]="defaultSelect">?</mat-option>
                <ng-container *ngFor="let module of availableModuels">
                    <mat-option [value]="module">
                        <ng-container *ngIf="module == 'projects'">Projektek</ng-container>
                        <ng-container *ngIf="module == 'maintenance'">Szervízek</ng-container>
                        <ng-container *ngIf="module == 'contacts'">Telefonkönyv</ng-container>
                        <ng-container *ngIf="module == 'items'">Termékek</ng-container>
                        <ng-container *ngIf="module == 'projectplanning'">Folyamat Tevezés</ng-container>
                    </mat-option>
                </ng-container >
            </mat-select>
        </mat-form-field>
    </div> 

    <ng-container *ngIf="roleAccesses">
        <form  #f="ngForm">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <td>Module</td>
                    <td>Hozzáférés</td>
                    <td>
                        <mat-icon matTooltip="Hozzá lett rendelve vagy Felelős érte. Ha az adatnak nincs értelme Felelőst adni akkor Saját Adat azé aki létrehozta.">info</mat-icon>
                        Saját Adat
                    </td>
                    <td>
                        <mat-icon matTooltip="Más Felhasználó adatai">info</mat-icon>
                         Mások Adata
                    </td>
                    <td></td>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let roleAccess of roleAccesses">
                    <tr>
                        <td>
                            <ng-container *ngIf="roleAccess.module == 'projects'">Projektek</ng-container>
                            <ng-container *ngIf="roleAccess.module == 'maintenance'">Szervízek</ng-container>
                            <ng-container *ngIf="roleAccess.module == 'contacts'">Telefonkönyv</ng-container>
                            <ng-container *ngIf="roleAccess.module == 'items'">Termékek</ng-container>
                            <ng-container *ngIf="roleAccess.module == 'projectplanning'">Folyamat Tevezés</ng-container>
                        </td>
                        <td>
                            <app-static-true-false-icon [value]="roleAccess.accessLevelMyself > 0"></app-static-true-false-icon> 
                        </td>
                        <td>
                            <mat-form-field appearance="fill">
                                <mat-select [(value)]="roleAccess.accessLevelMyself">
                                    <mat-option [value]="0">Nincs Hozzáférés</mat-option>
                                    <mat-option [value]="1">Látható</mat-option>
                                    <mat-option [value]="2">Szerkesztés</mat-option>
                                </mat-select>
                              </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="fill">
                                <mat-select [(value)]="roleAccess.accessLevelOthers">
                                    <mat-option [value]="0">Nincs Hozzáférés</mat-option>
                                    <mat-option [value]="1">Látható</mat-option>
                                    <mat-option [value]="2">Szerkesztés</mat-option>
                                </mat-select>
                              </mat-form-field>
                        </td>
                        <td>
                            <p class="flex">
                                <span class="padding-l-10">
                                    <button mat-mini-fab  color="primary" (click)="save(roleAccess)"  [disabled]="f.invalid" matTooltip="Mentés">
                                        <mat-icon>save</mat-icon>
                                    </button>
                                </span>
                                <span class="padding-l-10">
                                    <button mat-mini-fab  color="warn"  matTooltip="Törlés" (confirm-click)="delete(roleAccess)" confirm>
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </span>
                                <span class="padding-l-10">
                                    <app-quick-log-info [data]="roleAccess"></app-quick-log-info>
                                </span>
                            </p>              
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </form>
    </ng-container>
</div>

